<section id="mobileHeader" class="mobileView">
    <div class="container-fluid">
        <div class="row mobileFixedHeader primaryGradient">
            <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mobileHeadPadT" data-toggle="modal"
                data-target="#mobileSideBar">
                <i class="fa fa-bars mMenuToggle mT5 " aria-hidden="true"></i>
            </div>
            <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 padL0 mobileHeadPadT" 
            data-toggle="modal" data-target="#mobileSideBar">
                <div class="headerWithBackArrow">
                    <span >Contact Us</span>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container mb-5 mt-4">
<div class="row card">
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
<h5 class="text-center"> <b>Contact Us</b> </h5>
<!-- <div class="text-right"><p>Last updated on 10-01-2024 10:42:33</p></div> -->
<h6 class="pt-1 pb-1 note-spacing"> <b>You may contact us using the information below</b></h6>
<div class="row">
<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 text-right">
    <b>Registered Address &nbsp; :</b>
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9 register-address">
 FOOD JUNCTION STORE
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 text-right">
    <b>Merchant Legal entity name &nbsp; :</b>
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
 SURVEY NO. 76-19-A1, SHOP NO. 14, ANUSAYA ENCLAVE, WANWADI,
PUNE, Pune, Maharashtra, 411040, Pune, Maharashtra, PIN: 411040
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 text-right">
    <b>Operational Address &nbsp; :</b>
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
  SURVEY NO. 76-19-A1, SHOP NO. 14, ANUSAYA ENCLAVE, WANWADI,
PUNE, Pune, Maharashtra, 411040, Pune, Maharashtra, PIN: 411040
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 text-right">
    <b>Telephone No &nbsp; :</b>
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
    9545020002
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 text-right">
    <b>E-Mail ID &nbsp; :</b>
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
    foodjunction.store@gmail.com
</div>
</div>
<!-- <h6>Merchant Legal entity name: FOOD JUNCTION STORE</h6>
<h6>Registered Address: SURVEY NO. 76-19-A1, SHOP NO. 14, ANUSAYA ENCLAVE, WANWADI,
    PUNE, Pune, Maharashtra, 411040, Pune, Maharashtra, PIN: 411040</h6> -->
    <!-- <h6>Operational Address: SURVEY NO. 76-19-A1, SHOP NO. 14, ANUSAYA ENCLAVE, WANWADI,
        PUNE, Pune, Maharashtra, 411040, Pune, Maharashtra, PIN: 411040</h6> -->
        <!-- <h6>Telephone No: 9545020002</h6> -->
        <!-- <h6>E-Mail ID: foodjunction.store@gmail.com</h6> -->
</div>
</div>
    </div>
</section>
