import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { CommanservicesService } from './services/commanservices.service';;
import { SwUpdate } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { TokenStorageService } from './services/token-storage.service';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Food Junction';
  browserRefresh: any;
  subscription: any;
  data: any = {};

  public showErrorPage: boolean = false;
  public hideMobileFooter: boolean = false;


  constructor(
    public dataService: CommanservicesService,
    private swUpdate: SwUpdate,
    private route: ActivatedRoute,
    public cookie: CookieService,
    public tokenStorage: TokenStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router) {
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.getMessage().subscribe((msg) => {
        if (msg === "pageChange") {
          this.checkURL();
        }
      });

      // route.queryParams.subscribe(params => {
      //   if (params.itemName) {
      //     console.log(params.itemName);
      //     this.router.navigate(['/web/ag/product', params.itemName]);
      //   }
      // });


      // this.subscription = router.events.subscribe((event) => {
      //   if (event instanceof NavigationStart) {
      //     // browserRefresh = !router.navigated;
      //   }
      // });
      router.events.subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event)
      });

      if (!browserRefresh) {
        // this.tokenStorage.getToken();
        // this.dataService.appConstant.getAccessToken(refresh);
        // if (this.cookie.check('auth-refreshtoken')) {
        //   let refresh = this.cookie.get('auth-refreshtoken');
        //   this.dataService.appConstant.getAccessToken(refresh);
        // }
      }

    }
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }
  }

  checkURL() {
    if (this.router.url == '/web/not-available') {
      this.showErrorPage = true;
    } else {
      this.showErrorPage = false;
    }

    if (this.router.url == '/web/unsubscribe-newsletter/') {
      this.hideMobileFooter = true;
    } else {
      this.hideMobileFooter = false;
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
    }
    if (event instanceof NavigationEnd) {
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
    }
    if (event instanceof NavigationError) {
    }
  }

  onActivate() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
}
