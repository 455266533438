import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public isCategoryLoading: boolean = false;
  public pagination: any = { pageNum: 1, numPerPage: 10}
  public categoryList: any = []
  public data: any;
  
  public storesList:any = []
  public footerData :any = {};


  constructor(
    public dataService: CommanservicesService,
    private title: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,) {
    if (isPlatformBrowser(this.platformId)) {
      this.categoryList = JSON.parse(sessionStorage.getItem("categoryList"))
      this.dataService.getMessage().subscribe((msg) => {
        if(msg=='categoryUpdated'){
          this.categoryList = JSON.parse(sessionStorage.getItem("categoryList"))
        }
      })
    }
  }

  ngOnInit() {
    this.getFooterData();
    this.getStore();
  }


  public getCategory(): void {
    this.isCategoryLoading = true;
    let item = { "pageNum": 1, "numPerPage": 20, "searchKey": null, "saveFilter": "", "userFilter": [] }
    this.dataService.openPostRequest(item, this.dataService.appConstant.SERVER_URLS['GET_CATEGORIES']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.categoryList = this.data.result;
        sessionStorage.setItem('categoryList',JSON.stringify(this.categoryList))
        this.dataService.sendMessage("categoryUpdated")
        this.isCategoryLoading = false;
      } else {
        this.isCategoryLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isCategoryLoading = false;
    });
  }


  getStore() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_WAREHOUSE"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.storesList = this.data.result;
      }
    },err=>{
    })
  }


  scrollTop(){
    window.scroll(0, 0); 
  }
  gotoContactUs(){
    this.router.navigate(['web/contact-us']);  
  }
  
  goToPrivacyPolicy(tab){
    
    console.log('tab in footer=============',tab);
    
    this.router.navigate(['web/foodJunction',tab]);
    if (tab == 'Privacy_Policy') {
      this.dataService.sendMessage("privacyPolicy")
    }else if(tab=='About_Us'){
      this.dataService.sendMessage("About_Us")
    } else if(tab=='Terms_and_Conditions') {
      this.dataService.sendMessage("tearmCondition")
    } else if(tab=='Return_Policy') {
      this.dataService.sendMessage("returnPolicy")
    }

  }

  
 getFooterData() {
  this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_FOOTER_DATA"]).subscribe(result => {
    this.data = result;
    if (this.dataService.appConstant.SUCCESS === this.data.status) {
      this.footerData = this.data.result;
    }
  }, err => {
  })
}

}
