import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { of, Subject } from 'rxjs';
const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'agGrocery';
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  accessToken: any;
  private subject = new Subject<any>();
  warehouseData: any = {};
  constructor(private cookie: CookieService, private router: Router) { }
  signOut(): void {
    alert("401 Error Log Out");
    this.cookie.deleteAll();
    this.cookie.delete('auth-refreshtoken');
    this.sendMessage('logged_out')
    this.router.navigate(['/web/home']);
    this.sendMessage('clearSearchBar');
    this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
    localStorage.clear();
    this.sendMessage('cartListUpdated');
    localStorage.setItem('warehouse', JSON.stringify(this.warehouseData));
    window.location.reload();
  }

  logoutWithMessage() {
    this.errorMsg("Session Expire Sign-In Again !!....");
    setTimeout(() => {
      this.cookie.deleteAll();
      this.cookie.delete('auth-refreshtoken');
      this.sendMessage('logged_out')
      this.router.navigate(['/web/home']);
      this.sendMessage('clearSearchBar');
      this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
      localStorage.clear();
      this.sendMessage('cartListUpdated');
      localStorage.setItem('warehouse', JSON.stringify(this.warehouseData));
      window.location.reload();
    }, 1000);
  }

  public errorMsg(statusCode: any) {
    // this.translateMessage(statusCode, (message) => {
    var x = document.getElementById("error-snackbar");
    x.innerHTML = '<span class="successmsg">' + statusCode + '</span>';
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    // });
  }

  public saveToken(token: string): void {
    this.accessToken = token
    // this.cookie.delete(TOKEN_KEY);
    // this.cookie.set(TOKEN_KEY, token)
    // window.sessionStorage.removeItem(TOKEN_KEY);
    // window.sessionStorage.setItem(TOKEN_KEY, token);
    // const user = this.getUser();
    // if (user.userUniqueId) {
    //   this.saveUser({ ...user, accessToken: token });
    // }
  }
  public getToken(): string | null {
    if (this.accessToken) {
      return this.accessToken;
    }
  }
  public saveRefreshToken(token: string): void {
    this.cookie.delete(REFRESHTOKEN_KEY);
    this.cookie.set(REFRESHTOKEN_KEY, token, 30, '/', '', true, "None");
  }
  public getRefreshToken(): string | null {
    if(this.cookie.get(REFRESHTOKEN_KEY)){
      return this.cookie.get(REFRESHTOKEN_KEY);
    }else{
      if(localStorage.getItem(USER_KEY) && null !=localStorage.getItem(USER_KEY) ){
        this.signOut();
      }
    }
  }
  public saveUser(user: any): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    // window.localStorage.removeItem(USER_KEY);
    // window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public getUser(): any {
    const user = localStorage.getItem(USER_KEY) || "{}";
    if (user) {
      return JSON.parse(user);
    }
    return of(null);
  }

  sendMessage(message: any) {
    this.subject.next(message);
  }
}