import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstantsService } from '../constants/app-constants.service';
import { PolicyData } from '../component/appModels/policy.model';
import { isPlatformBrowser } from '@angular/common';
declare var jquery: any;
declare var $: any;


@Injectable({
  providedIn: 'root'
})
export class CommanservicesService {

  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  headers: Headers;
  timeZone: any;

  public organizationId: any;
  public payloadData: any = {};

  constructor(private router: Router, public http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, public appConstant: AppConstantsService,) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json; charset=utf-8');
    this.headers.append('Accepts', '*/*');
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.getOrganizationId();
    this.getCartList();
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message });
  }

  error(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'error', text: message });
  }

  sendMessage(message: any) {
    console.log(message);
    this.subject.next(message);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }



  getOrganizationId() {
    var organizationId: string;
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('Food_Junction_Org')) {
        var user = JSON.parse(localStorage.getItem('Food_Junction_Org'));
        if (user) {
          return organizationId = user.organizationProfileId;
        }
      }
    }
  }


  /**
   * Post Request
   */

  getPincodeUrl(url: string) {
    return this.http.get<any>(this.appConstant.PINCODE_API + url, {

    });
  }

  getRequest(url: string): Observable<any> {
    console.log("80");
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.get<any>(this.appConstant.HOME_URL + url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, 's_a_t': this.appConstant.authHeader(), organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME })

    });
  }


  // NGXS
  // dummyGet() {
  //   let orgId: string;
  //   orgId = '' + this.getOrganizationId();
  //   return this.http.get<any>('http://localhost:8080/v1/brand/get-all-brands', {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME })
  //   });
  // }


  getOrganization(url: string): Observable<any> {
    console.log("100");
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.get<any>(this.appConstant.HOME_URL + url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, tenant_name: this.appConstant.TENANT_NAME })
    });
  }

  postRequest(data: any, url: string): Observable<any> {
    console.log("108");
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.post<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 's_a_t': this.appConstant.authHeader(), 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME }) });
  }

  postRequestWithoutOrg(data: any, url: string): Observable<any> {
    return this.http.post<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 's_a_t': this.appConstant.authHeader(), 'timezone': this.timeZone, tenant_name: this.appConstant.TENANT_NAME }) });
  }

  putRequest(data: any, url: string): Observable<any> {
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.put<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 's_a_t': this.appConstant.authHeader(), 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME }) });
  }

  LoginRequest(data: any, url: string): Observable<any> {
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.post<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME }) });
  }

  deleteRequest(url: string): Observable<any> {
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.delete<any>(this.appConstant.HOME_URL + url, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 's_a_t': this.appConstant.authHeader(), 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME }) });
  }





  //OPEN 


  openGetRequest(url: string): Observable<any> {
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.get<any>(this.appConstant.HOME_URL + url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME })
    });
  }

  openPostRequest(data: any, url: string): Observable<any> {
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.post<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME }) });
  }

  openPutRequest(data: any, url: string): Observable<any> {
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.put<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME }) });
  }

  openDeleteRequest(url: string): Observable<any> {
    let orgId: string;
    orgId = '' + this.getOrganizationId();
    return this.http.delete<any>(this.appConstant.HOME_URL + url, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'timezone': this.timeZone, organizationId: orgId, tenant_name: this.appConstant.TENANT_NAME, }) });
  }




  postRequestMasterData(data: any, url: string): Observable<any> {
    return this.http.post<any>(this.appConstant.MASTER_API + url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }


  public getCartList(): void {
    if (isPlatformBrowser(this.platformId)) {
      let user = JSON.parse(localStorage.getItem("agGrocery"));
      let wareHouse = JSON.parse(localStorage.getItem("warehouse"));
      let data;
      if (user && wareHouse) {
        this.payloadData.userUid = user.userUniqueId;
        this.payloadData.warehouseId = wareHouse.warehouseId;
        this.postRequest(this.payloadData, this.appConstant.SERVER_URLS['CART_LIST']).subscribe(result => {
          data = result;
          if (this.appConstant.SUCCESS === data.status) {
            let itemsList = data.result;
            localStorage.setItem('cartList', JSON.stringify(data.result));
            //CALCULATE TOTAL
            let totalAmount = 0;
            let salePrice = 0;
            let savedAmount = 0;
            let items = 0;
            if (itemsList && itemsList.length > 0) {
              totalAmount = 0;    //REinitialize both varibale before adding new 
              items = 0;
              salePrice = 0;
              savedAmount = 0;
              items = itemsList.length;
              itemsList.forEach((element: any) => {
                if (element.isStockAvailable) {
                  totalAmount += element.finalPrice * element.quantity;
                  salePrice += element.salePrice;
                }
              });

              savedAmount = salePrice - totalAmount;
              let cartTotal = {
                totalAmount: totalAmount,
                savedAmount: savedAmount,
                items: items
              }
              localStorage.setItem('cartTotal', JSON.stringify(cartTotal));
            } else {
              totalAmount = 0;    //REinitialize both varibale before adding new 
              items = 0;
            }
            this.sendMessage('cartListUpdated')
          }
        }, err => {

        });
      }
    }
  }








  checkPermission(modulename: any, actionname: any) {
    let permission = false;
    if (null != sessionStorage.getItem('securityActions')) {
      JSON.parse(sessionStorage.getItem('securityActions')).forEach((value: any) => {
        if (value.module === modulename && value.actionName === actionname) {
          permission = true;
        }
      });
    }
    return permission;
  }

  // downloadFile(fileName) {
  //   let url = this.appConstants.GETFILE_URL + fileName + "/";
  //   // window.location.href = url;
  //   window.open(url, '_blank');
  // }

  downloadAttachFile(fileName: any) {
    let url = this.appConstant.GET_FILE + fileName + "/";
    // window.location.href = url;
    window.open(url, '_blank');
  }

  // prepareImportExcelFile(exfile, url) {

  prepareImportExcelFile(exfile: any, url: string): Observable<any> {
    let data = new FormData();
    data.append('importfile', exfile);
    return this.http.post<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 's_a_t': this.appConstant.authHeader(), 'timezone': this.timeZone }) });
  }

  importExcelFile(exfile: any, importData: any, url: string): Observable<any> {
    let data = new FormData();
    data.append('importfile', exfile);
    data.append('importStr', JSON.stringify(importData));
    return this.http.post<any>(this.appConstant.HOME_URL + url, data, { headers: new HttpHeaders({ 's_a_tcle': this.appConstant.authHeader(), 'timezone': this.timeZone }) });
  }



  throwError(err: any) {
    if (err.statusText == 'Unknown Error') {
      // this.router.navigate(['web/not-available']);
      this.sendMessage('pageChange')
    }
  }

}




