import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { HeaderComponent } from './component/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './component/footer/footer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeComponent } from './component/home/home.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
// import { TranslationService } from './translation/translation';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { OwlNativeDateTimeModule, OwlDateTimeModule } from "ng-pick-datetime";
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { NgxSpinnerModule } from 'ngx-spinner';
// import { MyHttpInterceptor } from './interceptor/my-http.interceptor';
import { ProductComponent } from './component/product/product.component';
import { MobileFooterComponent } from './component/mobile-footer/mobile-footer.component';
import { AppConstantsService } from './constants/app-constants.service';
import { CommanservicesService } from './services/commanservices.service';
import { CartService } from './services/cart.service';

import { SkeletonLoaderModule } from './component/skeleton-loader/skeleton-loader.module';
import { UnderMantananceComponent } from './component/under-mantanance/under-mantanance.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { Ng5SliderModule } from 'ng5-slider';
import { from } from 'rxjs';
import { WindowRef } from 'src/app/services/windowRef.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxPaginationModule } from 'ngx-pagination';


import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { StoreLocationComponent } from './component/store-location/store-location.component';
import { UnsubscribeNewsletterComponent } from './component/unsubscribe-newsletter/unsubscribe-newsletter.component';

// NGXS
import { NgxsModule } from '@ngxs/store';
import { PolicyState } from './store/state/policy.state';
import { CategorySate } from './store/state/category.state';
import { HomePageState } from './store/state/homepage.state';
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { DatePipe, HashLocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service'
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ProductComponent,
    MobileFooterComponent,
    UnderMantananceComponent,
    PrivacyPolicyComponent,
    StoreLocationComponent,
    UnsubscribeNewsletterComponent,
    ContactUsComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    // NGXS
    NgxsModule.forRoot([PolicyState, CategorySate, HomePageState]),
    // Ng2SearchPipeModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    // InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    SkeletonLoaderModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    TooltipModule,
    // NgxSliderModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule,
    AngularFireAuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    // TranslationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AppConstantsService,
    CommanservicesService,
    WindowRef,
    AuthService,
    TokenStorageService,
    CookieService,
    DatePipe
    // HashLocationStrategy

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
