import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { CommanservicesService } from 'src/app/services/commanservices.service';
@Component({
  selector: 'mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss']
})
export class MobileFooterComponent implements OnInit {


  public cartList: any = [];
  selectedTab: any;


  constructor(public router: Router,
    private title: Title,
    public dataService: CommanservicesService,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {

      this.cartList = JSON.parse(localStorage.getItem("cartList") );


      this.dataService.getMessage().subscribe((msg) => {
        if (msg == 'cartListUpdated') {
          this.cartList = JSON.parse(localStorage.getItem("cartList"));

        }
      });
    }
  }

  ngOnInit(): void {
    this.selectedTab = 'home';
  }


  msg() {
    this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
    // this.dataService.sendMessage("mobileHome");
  }

  goToCheckout() {
    this.router.navigate(['/web/ag/cart']);
  }


  goToProfile(tab: any) {
    this.router.navigate(['/web/ag/profile', tab]);
  }

  selectFooterTab(tab) {
    this.selectedTab = tab;
    if (tab == 'home') {
      this.router.navigate(["/"]);
    } else if (tab == 'category') {
      this.router.navigate(["web/ag/categories"]);
    } else if (tab == 'cart') {
      this.router.navigate(['/web/ag/cart']);
    } else if (tab == 'search') {
      this.router.navigate(["/web/ag/search"]);
    } else if (tab == 'wishList') {
      this.router.navigate(["/"]);
    }

  }


}
