<!-- <div class="mobile_footer primaryGradient">
        <div class="footer-tab-mobile" routerLink="/" (click)="msg()">
            <i class="icofont-home" ></i>
            <div class="mobile-view-footer-lable">Home</div>
        </div>
        <div class="footer-tab-mobile"  routerLink="/web/ag/categories" [queryParams]="{srs:'root'}">
            <i class="fa fa-th" aria-hidden="true"></i>
            <div class="mobile-view-footer-lable">Category</div>
        </div>
        <div class="footer-tab-mobile cart_div"  (click)="goToCheckout()">
            <i class="icofont-basket empty-cart"> </i>
            <div class="mobile-view-footer-lable">Cart</div>
            <div class="cart_count">{{cartList?.length?cartList?.length:"0"}}</div>
        </div>
        <div class="footer-tab-mobile"  routerLink="/web/ag/search" (click)="msg()">
            <i class="icofont-search-1"></i>
            <div class="mobile-view-footer-lable">Search</div>
        </div>
        <div class="footer-tab-mobile" (click)="goToProfile('my-Orders')" >
            <i class="icofont-list account-list-icons"></i>
            <div class="mobile-view-footer-lable">Orders</div>
        </div>
    </div> -->

<div id="footerpage">
    <div class="footer-pg row">
        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ion-col" (click)="selectFooterTab('home')">
            <div>
                <img *ngIf="selectedTab != 'home'" src="assets/img/footer_home.svg" alt="">
                <img *ngIf="selectedTab == 'home'" src="assets/img/home_fill.svg" alt="">
                <hr *ngIf="selectedTab == 'home'" class="hractivebor">
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ion-col" (click)="selectFooterTab('category')">
            <div>
                <img *ngIf="selectedTab != 'category'" src="assets/img/footer_category.svg" alt="">
                <img *ngIf="selectedTab == 'category'" src="assets/img/category_fill.svg" alt="">
                <hr *ngIf="selectedTab == 'category'" class="hractivebor">
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ion-col" (click)="selectFooterTab('cart')">
            <div>
                <span class="cartcount">{{cartList?.length?cartList?.length:"0"}}</span>
                <img *ngIf="selectedTab != 'cart'" src="assets/img/footer_cart.svg" alt="">
                <img *ngIf="selectedTab == 'cart'" src="assets/img/cart_fill.svg" alt="">
                <hr *ngIf="selectedTab == 'cart'" class="hractivebor">
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ion-col" (click)="selectFooterTab('search')">
            <div>
                <img *ngIf="selectedTab != 'search'" src="assets/img/footer_search.svg" alt="">
                <img *ngIf="selectedTab == 'search'" src="assets/img/footer_search_fill.svg" alt="">
                <hr *ngIf="selectedTab == 'search'" class="hractivebor">
            </div>
        </div>
        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ion-col" (click)="selectFooterTab('wishList')">
            <div>
                <img *ngIf="selectedTab != 'wishList'" src="assets/img/myList.svg" alt="">
                <img *ngIf="selectedTab == 'wishList'" src="assets/img/myListfill.svg" alt="">
                <hr *ngIf="selectedTab == 'wishList'" class="hractivebor">
            </div>
        </div>
    </div>
</div>