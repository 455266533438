export const environment = {
  production: true,

  serverUrl: "https://erpapi.foodjunctionstore.com/v1/",
  // serverUrl: "https://erpapi.apnagrahak.in/v1/",
  // serverUrl: "http://localhost:8070/v1/",
  serverUrl2: "https://masterapi.apnagrahak.in/v1/",
  ImageUrl: "https://apnagrahak.s3.ap-south-1.amazonaws.com/",
  tenantName: "foodjunctionstore",
  PincodeUrl: "https://api.postalpincode.in/",
  ImgBaseUrl: "https://ik.imagekit.io/fjimgkit/",

  // cashfree payment url
  paymentUrl: "https://www.cashfree.com/checkout/post/submit",




  firebase: {
    apiKey: "AIzaSyD2QgUhSCRzgaBfbKGuj5dfXhtCALaZw_Q",
    authDomain: "foodjunction-7d0b0.firebaseapp.com",
    projectId: "foodjunction-7d0b0",
    storageBucket: "foodjunction-7d0b0.appspot.com",
    messagingSenderId: "369404790039",
    appId: "1:369404790039:web:79d7299c8e5f9fbcfd2352",
    measurementId: "G-S8970KZ8YS"
  }


};
