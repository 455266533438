import { Component, OnInit, HostListener, PLATFORM_ID, Inject, NgZone, Pipe, PipeTransform, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { AppConstantsService } from 'src/app/constants/app-constants.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { HomePageState } from 'src/app/store/state/homepage.state';
import { getHomePageLists, getPageId, getPageWithSeqNo } from 'src/app/store/actions/homepage.action';
declare var $: any;
declare var google;
@Pipe({ name: 'pairs' })

export class PairsPipe implements PipeTransform {
  transform(value: any) {
    return value.filter((v: any, i: any) => i % 6 == 0).map((v: any, i: any) => [value[i * 6], value[i * 6 + 1]])
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [PairsPipe],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]
})
export class HomeComponent implements OnInit, OnDestroy {

  @Select(HomePageState.getPageListData) pageList$: Observable<any[]>;

  @Select(HomePageState.getPageListLoaded) pageListLoaded$: Observable<boolean>;

  pageListloadedSub: Subscription;

  @Select(HomePageState.getPageListByIdData) pageListById$: Observable<any[]>;

  @Select(HomePageState.getPageListByIdLoaded) pageListByIdLoaded$: Observable<boolean>;

  pageListByIdloadedSub: Subscription;

  // page seq No
  @Select(HomePageState.getPageWithSeqNoData) pageWithSeqNo$: Observable<any[]>;

  @Select(HomePageState.getPageWithSeqNoLoaded) pageWithSeqNoLoaded$: Observable<boolean>;

  pageWithSeqNoloadedSub: Subscription;

  advertisingSlider: OwlOptions = {
    loop: false,
    items: 1,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };

  // advertisingSlider2: OwlOptions = {
  //   loop: false,
  //   items: 4,
  //   autoWidth: true,
  //   mouseDrag: true,
  //   autoplay: false,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: false,
  //   navSpeed: 700,
  //   navText: ["<img src='assets/img/Left Scroll.png' style:'width:33px'>", "<img src='assets/img/Right Scroll.png'>"],
  //   responsive: {
  //     0: {
  //       items: 2
  //     },
  //     400: {
  //       items: 3
  //     },
  //     740: {
  //       items: 4
  //     },
  //     940: {
  //       items: 5
  //     }
  //   },
  //   nav: true
  // };
  bestofferSlider: OwlOptions = {
    loop: false,
    items: 4,
    autoWidth: true,
    mouseDrag: true,
    autoplay: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<img src='assets/img/Left Scroll.png' style:'width:33px'>", "<img src='assets/img/Right Scroll.png'>"],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    },
    nav: true
  };
  apnagrocerySlider: OwlOptions = {
    loop: false,
    items: 4,
    autoWidth: true,
    mouseDrag: true,
    autoplay: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<img src='assets/img/Left Scroll.png' style:'width:33px'>", "<img src='assets/img/Right Scroll.png'>"],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    },
    nav: true
  };
  recomendationSlider: OwlOptions = {
    loop: false,
    items: 4,
    autoWidth: true,
    mouseDrag: true,
    autoplay: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<img src='assets/img/Left Scroll.png' style:'width:33px'>", "<img src='assets/img/Right Scroll.png'>"],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 5
      },
      740: {
        items:5
      },
      940: {
        items: 5
      }
    },
    nav: true
  };
  // frequentlySlider: OwlOptions = {
  //   loop: false,
  //   items: 4,
  //   autoWidth: true,
  //   mouseDrag: true,
  //   autoplay: false,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: false,
  //   navSpeed: 700,
  //   navText: ["<img src='assets/img/Left Scroll.png' style:'width:33px'>", "<img src='assets/img/Right Scroll.png'>"],
  //   responsive: {
  //     0: {
  //       items: 2
  //     },
  //     400: {
  //       items: 3
  //     },
  //     740: {
  //       items: 4
  //     },
  //     940: {
  //       items: 5
  //     }
  //   },
  //   nav: true
  // };

  recentlySlider: OwlOptions = {
    loop: false,
    items: 4,
    autoWidth: true,
    mouseDrag: true,
    autoplay: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["<img src='assets/img/Left Scroll.png' style:'width:33px'>", "<img src='assets/img/Right Scroll.png'>"],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    },
    nav: true
  };


  public isLoading: boolean = false;
  public user: any = {};
  public data: any;

  public previewDataList: any = [];
  public freshPreviewDataList: any = []
  public getPageList: any = [];
  public homePage: any;
  public currencySymbol: any;

  public userUniqueId;
  public productListIndex: any;
  public cmsSectionContentIndex: any;
  public itemIndex: any;
  public cartList: any = [];
  public proceedToAdd: any = {};
  public sequenceList: any = [];
  public seqNoIndex: any = 0;

  public pagination: any = { seqnoList: [] }
  public isMobileView: boolean = false;
  public isHomeSectionLoading: boolean = false;
  public skeletonSection: any = [{}, {}, {}]





  public mainSlider: any = {};
  public topCategoryView: any = {};
  public topBrandView: any = {};
  public topProductView: any = {};
  public bestSellerProduct = {};
  public websiteProduct: any = {};
  public recommendationView: any = {};
  public recentlyView: any = {};
  public frequentlyView: any = {};
  public subSlider2: any = {};
  searchKeyMobile: any;
  public storesList: any = [];
  viewAll: any = 'viewAll';
  public itemLoader: boolean = false;
  public warehouseData: any = {};
  public showButton = false;
  deferredPrompt: any;
  public currLat: any;
  public currLng: any
  imgWidth: number;
  imgHeight: number;
  bannerSectionView: any = {};
  bannerSection3: any = {};
  bannerSection4: any = {};
  ecommPageUid: any;
  promotedBrandView:any ={};
  addToCartQtyLimit:number=0;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }
  constructor(
    public dataService: CommanservicesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public constant: AppConstantsService,
    public router: Router,
    private store: Store,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private zone: NgZone,
    protected sanitizer: DomSanitizer,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.sendMessage('pageChange')
      sessionStorage.setItem('pageName', "HomePage")
      this.dataService.sendMessage('clearSearchBar')
      this.user = JSON.parse(localStorage.getItem("agGrocery") || "{}");
      this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
      if (this.user) {
        this.userUniqueId = this.user.userUniqueId
        this.cartList = JSON.parse(localStorage.getItem("cartList") || "{}");
      }
      this.currencySymbol = this.dataService.appConstant.getCurrencySymbol();


      this.dataService.getMessage().subscribe((msg) => {
    
        if (msg === "LoggedIn") {
          this.user = JSON.parse(localStorage.getItem("agGrocery") || "{}");
        }
        if (msg === "HomePage") {
          this.productListIndex = sessionStorage.getItem('productListIndex');
          this.cmsSectionContentIndex = sessionStorage.getItem('cmsSectionContentIndex');
          this.itemIndex = sessionStorage.getItem('itemIndex');

          this.addToCart(this.productListIndex, this.cmsSectionContentIndex, this.itemIndex)
          sessionStorage.removeItem('pageName');
          sessionStorage.removeItem('productListIndex');
          sessionStorage.removeItem('cmsSectionContentIndex');
          sessionStorage.removeItem('itemIndex');
        }
        if (msg == 'cartListUpdated') {
          this.cartList = JSON.parse(localStorage.getItem("cartList") || "{}");
          this.updateProductList();
        }
        if (msg == 'showLoader') {
          this.isLoading = true
        }
        if (msg == 'hideLoader') {
          this.isLoading = false
        }
        if (msg == 'hideMoreLoader') {
          this.itemLoader = false;
        }
        if (msg == 'Selected_Warehouse') {
          this.warehouseData = JSON.parse(localStorage.getItem("warehouse") || "{}");
          // if (this.warehouseData) {
          //   this.paginationProd.warehouseId = this.warehouseData.warehouseId;
          //   window.location.reload();
          // }
          this.getPageBySeqNoWithoutLoader();
        }

      });
      this.title.setTitle("Food Junction World Food Gourmet Store and Online Supermarket in Pune");
      this.meta.updateTag({ name: 'description', content: "An online grocer is either a brick-and-mortar supermarket or grocery store that allows online ordering, or a standalone e-commerce service that includes grocery items. ... The online ordering is done through e-commerce websites or mobile apps." });
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 568) {
        this.imgWidth = 100;
        this.imgHeight = 100;
      } else {
        this.imgWidth = 300;
        this.imgHeight = 300;
      }

      var btn = $('#backToTopbutton');

      $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
          btn.addClass('show');
        } else {
          btn.removeClass('show');
        }
      });

      btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
      });
    }
    this.getDomainWiseOrgnization();
    setTimeout(() => {
      if (localStorage.getItem("Food_Junction_Org")) {
        let orgObj= JSON.parse(localStorage.getItem("Food_Junction_Org"));
        if(orgObj && orgObj.cartLimitCount &&  +orgObj.cartLimitCount>0){
          this.addToCartQtyLimit=+ orgObj.cartLimitCount;
        }else{
          this.addToCartQtyLimit=300000;
        }
      }else{
        this.addToCartQtyLimit=300000;
      }
    }, 200);
  }


  getDomainWiseOrgnization() {
    let Obj: any = {
      "domain": "https://foodjunctionstore.com/"
    }
    this.dataService.postRequestWithoutOrg(Obj, this.dataService.appConstant.SERVER_URLS['ORGNIZATION_NEW_API']).subscribe(response => {
      this.data = response.result;
      if (this.dataService.appConstant.SUCCESS === response.status) {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('Food_Junction_Org', JSON.stringify(this.data));
        }
        this.getOrganization();
        this.getHomePageList();
        // this.getSeqList();
        if (!this.pageWithSeqNo$) {
          this.isLoading = true
        }
      }
    });
  }



  //GET ORGANIZATION
  getOrganization() {
    this.dataService.getOrganization(this.dataService.appConstant.SERVER_URLS['GET_ORGANIZATION']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === result.status) {
        localStorage.setItem("primary_organization", JSON.stringify(this.data.result))
      }
    }, err => {
      this.dataService.throwError(err)
      // this.isLoading = false;
    })
  }




  getSeqList() {
    // this.isLoading = true;
    this.pageListByIdloadedSub = this.pageListByIdLoaded$.subscribe(loadedById => {
      if (!loadedById) {
        this.store.dispatch(new getPageId(this.homePage.ecommPageUid));
        // this.isLoading = false;
      }
    })
    this.pageListById$.subscribe(result => {
      this.data = result;
      // this.isLoading = false;
      this.sequenceList = result;
      if (this.sequenceList && this.sequenceList.length > 0) {
        // this.sequenceList.forEach(element => {
        //   this.pagination.seqnoList.push(element.seqNo)
        // });
        this.pagination.seqnoList.push(this.sequenceList[0].seqNo)
        this.pagination.seqnoList.push(this.sequenceList[1].seqNo)
        this.seqNoIndex = 2;
        this.getPageBySeqNo()
      }
      // setTimeout(() => {
      //   this.getPageBySeqNo()
      // }, 200);
    })
    // this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['GET_SEQ_OF_PAGE'] + "/" + this.homePage.ecommPageUid).subscribe(result => {
    //   this.data = result;
    //   if (this.dataService.appConstant.SUCCESS === result.status) {
    //     this.sequenceList = this.data.result;
    //     this.pagination.seqnoList.push(this.sequenceList[0].seqNo)
    //     this.pagination.seqnoList.push(this.sequenceList[1].seqNo)
    //     this.seqNoIndex = 2
    //     this.getPageBySeqNo()
    //   }
    // }, err => {
    //   this.dataService.throwError(err)
    //   this.isLoading = false;
    // })
  }




  @HostListener('window:scroll', ['$event'])
  onScrollOrder(event) {
    if (!this.isHomeSectionLoading) {
      this.pagination.seqnoList = []
      
      if (this.sequenceList && this.seqNoIndex && this.seqNoIndex < this.sequenceList.length && this.seqNoIndex) {
        this.pagination.seqnoList.push(this.sequenceList[this.seqNoIndex].seqNo)
        if (this.seqNoIndex < this.sequenceList.length && this.seqNoIndex) {
          this.seqNoIndex += 1;
          // this.pagination.seqnoList.push(this.sequenceList[this.seqNoIndex].seqNo)
          // this.seqNoIndex += 1;
        }
        // this.getPageBySeqNoWithoutLoader();
        // this.getPageBySeqNo();
      }
    }



    // if (!this.isHomeSectionLoading) {
    //   this.pagination.seqnoList = []

    //   if (this.seqNoIndex < this.sequenceList.length && this.seqNoIndex) {
    //     this.pagination.seqnoList.push(this.sequenceList[this.seqNoIndex].seqNo)
    //     if (this.seqNoIndex < this.sequenceList.length && this.seqNoIndex) {
    //       this.seqNoIndex += 1;
    //       this.pagination.seqnoList.push(this.sequenceList[this.seqNoIndex].seqNo)
    //       this.seqNoIndex += 1;
    //     }
    //     this.getPageBySeqNoWithoutLoader()
    //   }
    // }


  }




  getPageBySeqNoWithoutLoader() {
    this.isHomeSectionLoading = true;
    this.pagination.ecommPageUid = this.homePage.ecommPageUid;
    if (this.user) {
      this.pagination.userUid = this.user.userUniqueId;
    }
    console.log("----------------------------------------");
    
    if(this.warehouseData && this.warehouseData.warehouseId){
      this.pagination.warehouseId=this.warehouseData.warehouseId;
    }
    // this.pageWithSeqNoloadedSub = this.pageWithSeqNoLoaded$.subscribe(loadedBySeq => {
    // if (!loadedBySeq) {
    // this.store.dispatch(new getPageWithSeqNo(this.pagination));
    // this.isLoading = false;
    // }
    // })
    // this.pageWithSeqNo$.subscribe(result => {
    //   this.data = result;
    // })
    this.dataService.openPostRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['GET_PAGE_BY_SEQ']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === result.status) {
        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        this.data.result.forEach((element: any) => {
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
            element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
              if (element1.itemList && element1.itemList.length > 0) {
                element1.itemList.forEach((item: any) => {
                  item.isAdded = false
                  item.quantity = 1;
                });
              }
            });
          }
        });


        // this.data.result.forEach(element => {
        //   this.freshPreviewDataList.push(element)
        // });

        let isPushes = false;
        // this.data.result.forEach(element => {
        //   isPushes = false;
        //   this.previewDataList.forEach(element1 => {
        //     if (element1.seqNo == element.seqNo) {
        //       isPushes = true
        //     }
        //   });
        //   if (!isPushes) {
        //     if (!element.showOnlyLoginUser) {
        //       this.previewDataList.push(element)
        //     } else {
        //       if (this.user) {
        //         this.previewDataList.push(element)
        //       }
        //     }
        //   }
        // });
        this.previewDataList=this.data.result;
        this.freshPreviewDataList = this.previewDataList;
        this.previewDataList.forEach((element, key) => {
          element.previewListIndex = key
          //DONE
          if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS7188529240') {
            if (!element.showOnlyLoginUser) {
              this.mainSlider = element
            } else {
              if (this.user.userUniqueId) {
                this.mainSlider = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH0502266720') {
            if (!element.showOnlyLoginUser) {
              this.topCategoryView = element
            } else {
              if (this.user.userUniqueId) {
                this.topCategoryView = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3859218777') {
            if (!element.showOnlyLoginUser) {
              this.topBrandView = element
            } else {
              if (this.user.userUniqueId) {
                this.topBrandView = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH4784391729') {
            if (!element.showOnlyLoginUser) {
              this.topProductView = element
            } else {
              if (this.user.userUniqueId) {
                this.topProductView = element
              }
            }
          }
          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH6829117111') {
            if (!element.showOnlyLoginUser) {
              this.bestSellerProduct = element
            } else {
              if (this.user.userUniqueId) {
                this.bestSellerProduct = element
              }
            }
          }


          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH5387819003') {
            if (!element.showOnlyLoginUser) {
              this.websiteProduct = element;
            } else {
              if (this.user.userUniqueId) {
                this.websiteProduct = element;
              }
            }
          }

          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH7173940210') {
            if (!element.showOnlyLoginUser) {
              this.recommendationView = element
            } else {
              if (this.user.userUniqueId) {
                this.recommendationView = element
              }
            }
          }

          //DONE
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3057948827') {
            if (!element.showOnlyLoginUser) {
              this.recentlyView = element
              console.log('this.recentlyView11',this.recentlyView);
            } else {
              if (this.user.userUniqueId) {
                this.recentlyView = element
                console.log('this.recentlyView22',this.recentlyView);
                
              }
            }
          }

          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH2092496496') {
            if (!element.showOnlyLoginUser) {
              this.frequentlyView = element
            } else {
              if (this.user.userUniqueId) {
                this.frequentlyView = element
              }
            }
          }
          //DONE
          if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS2851504776') {
            if (!element.showOnlyLoginUser) {
              this.subSlider2 = element
            } else {
              if (this.user.userUniqueId) {
                this.subSlider2 = element
              }
            }
          }
        });


        if (this.user && this.cartList && this.cartList.length > 0) {
          this.previewDataList.forEach((element: any) => {
            if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
              element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
                if (element1.itemList && element1.itemList.length > 0) {
                  element1.itemList.forEach((item: any) => {
                    this.cartList.forEach((cartItem: any) => {
                      if (item.itemUid == cartItem.itemUid) {
                        item.isAdded = true
                        item.quantity = cartItem.quantity;
                        item.cartId = cartItem.cartId;
                      }
                    });
                  });
                }
              });
            }
          });
        }
        this.isHomeSectionLoading = false;

      } else {
        this.isHomeSectionLoading = false;
      }
    }, err => {
      this.isHomeSectionLoading = false;
      this.dataService.throwError(err)
    })

  }




  getPageBySeqNo() {
    this.itemLoader = true;
    this.pagination.ecommPageUid = this.homePage.ecommPageUid;
    if (this.user) {
      this.pagination.userUid = this.user.userUniqueId;
    }
    this.pageWithSeqNoloadedSub = this.pageWithSeqNoLoaded$.subscribe(loadedBySeq => {
      if (!loadedBySeq) {
        this.store.dispatch(new getPageWithSeqNo(this.pagination));
      }
    })
    this.pageWithSeqNo$.subscribe(result => {
      // this.itemLoader = true;
      this.data = result;
      // this.itemLoader = true;
      //CHECK ITEM ALREADY ADDED INTO CART OR NOT
      if (result) {
        result.forEach((element: any) => {
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
            element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
              if (element1.itemList && element1.itemList.length > 0) {
                element1.itemList.forEach((item: any) => {
                  item.isAdded = false
                  item.quantity = 1;
                });
              }
            });
          }
        });
      }


      // this.data.result.forEach(element => {
      //   this.freshPreviewDataList.push(element)
      // });

      let isPushes = false;
      if (result) {
        result.forEach(element => {
          isPushes = false;
          this.previewDataList.forEach(element1 => {
            if (element1.seqNo == element.seqNo) {
              isPushes = true
            }
          });
          if (!isPushes) {
            if (!element.showOnlyLoginUser) {
              this.previewDataList.push(element)
            } else {
              if (this.user) {
                this.previewDataList.push(element)
              }
            }
          }
        });
      }

      this.freshPreviewDataList = this.previewDataList;


      // this.itemLoader = true;
      this.previewDataList.forEach((element, key) => {
        element.previewListIndex = key;
        //DONE
        if (element.pageContentUid == 'PGC2725171252') {



          if (!element.showOnlyLoginUser) {
            this.mainSlider = element
          } else {
            if (this.user.userUniqueId) {
              this.mainSlider = element
            }
          }


        }
        //DONE
        if (element.pageContentUid == 'PGC7874776730') {



          if (!element.showOnlyLoginUser) {
            this.topCategoryView = element
          } else {
            if (this.user.userUniqueId) {
              this.topCategoryView = element
            }
          }

        }
        //DONE
        if (element.pageContentUid == 'PGC2725171253') {


          if (!element.showOnlyLoginUser) {
            this.topBrandView = element
          } else {
            if (this.user.userUniqueId) {
              this.topBrandView = element
            }
          }

        }
        //DONE
        if (element.pageContentUid == 'PGC2725171254') {
          if (!element.showOnlyLoginUser) {
            this.topProductView = element
          } else {
            if (this.user.userUniqueId) {
              this.topProductView = element
            }
          }

        }
        //DONE
        if (element.pageContentUid == 'PGC2725171255') {


          if (!element.showOnlyLoginUser) {
            this.bestSellerProduct = element
          } else {
            if (this.user.userUniqueId) {
              this.bestSellerProduct = element
            }
          }

        }


        //DONE
        if (element.pageContentUid == 'PGC2725171256') {


          if (!element.showOnlyLoginUser) {
            this.websiteProduct = element
          } else {
            if (this.user.userUniqueId) {
              this.websiteProduct = element
            }
          }


        }

        if (element.pageContentUid == 'PGC3966040336') {

          if (!element.showOnlyLoginUser) {
            this.recommendationView = element
          } else {
            if (this.user.userUniqueId) {
              this.recommendationView = element
            }
          }

        }

        //DONE
        if (element.pageContentUid == 'PGC2725171257') {

          if (!element.showOnlyLoginUser) {
            this.recentlyView = element
          } else {
            if (this.user.userUniqueId) {
              this.recentlyView = element
            }
          }
        }

        if (element.pageContentUid == 'PGC0230302026') {

          if (!element.showOnlyLoginUser) {
            this.frequentlyView = element
          } else {
            if (this.user.userUniqueId) {
              this.frequentlyView = element
            }
          }

        }
                // //DONE
                // if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3057948827') {
                //   if (!element.showOnlyLoginUser) {
                //     this.recentlyView = element
                //     console.log('this.recentlyView11',this.recentlyView);
                //   } else {
                //     if (this.user.userUniqueId) {
                //       this.recentlyView = element
                //       console.log('this.recentlyView22',this.recentlyView);
                      
                //     }
                //   }
                // }

        if (element.pageContentUid == 'PGC8641054273') {

          if (!element.showOnlyLoginUser) {
            this.promotedBrandView = element
          } else {
            if (this.user.userUniqueId) {
              this.promotedBrandView = element
            }
          }
        }

        //DONE
        if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS2851504776') {

          if (!element.showOnlyLoginUser) {
            this.subSlider2 = element
          } else {
            if (this.user.userUniqueId) {
              this.subSlider2 = element
            }
          }


        }
        if (element.cmsSlider && element.pageContentUid == 'PGC9263443053') {
          this.bannerSectionView = element;

          // if (!element.showOnlyLoginUser) {
          //   this.topBrandView = element
          // } else {
          //   if (this.user.userUniqueId) {
          //     this.topBrandView = element
          //   }
          // }

        }

        if (element.cmsSlider && element.pageContentUid == 'PGC4683548075') {
          this.bannerSection3 = element;
        }

      });


      if (this.user && this.cartList && this.cartList.length > 0) {
        this.previewDataList.forEach((element: any) => {
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
            element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
              if (element1.itemList && element1.itemList.length > 0) {
                element1.itemList.forEach((item: any) => {
                  this.cartList.forEach((cartItem: any) => {
                    if (item.itemUid == cartItem.itemUid) {
                      item.isAdded = true
                      item.quantity = cartItem.quantity;
                      item.cartId = cartItem.cartId;
                    }
                  });
                });
              }
            });
          }
        });
      }
      // this.isLoading = false;
    })



    // this.dataService.openPostRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['GET_PAGE_BY_SEQ']).subscribe(result => {
    //   this.data = result;
    //   if (this.dataService.appConstant.SUCCESS === result.status) {

    //     this.data.result.forEach((element: any) => {
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
    //         element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
    //           if (element1.itemList && element1.itemList.length > 0) {
    //             element1.itemList.forEach((item: any) => {
    //               item.isAdded = false
    //               item.quantity = 1;
    //             });
    //           }
    //         });
    //       }
    //     });

    //     let isPushes = false;
    //     this.data.result.forEach(element => {
    //       isPushes = false;
    //       this.previewDataList.forEach(element1 => {
    //         if (element1.seqNo == element.seqNo) {
    //           isPushes = true
    //         }
    //       });
    //       if (!isPushes) {
    //         if (!element.showOnlyLoginUser) {
    //           this.previewDataList.push(element)
    //         } else {
    //           if (this.user) {
    //             this.previewDataList.push(element)
    //           }
    //         }
    //       }
    //     });

    //     this.freshPreviewDataList = this.previewDataList;



    //     this.previewDataList.forEach((element, key) => {
    //       element.previewListIndex = key
    //       //DONE
    //       if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS7188529240') {



    //         if (!element.showOnlyLoginUser) {
    //           this.mainSlider = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.mainSlider = element
    //           }
    //         }


    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH0145422180') {



    //         if (!element.showOnlyLoginUser) {
    //           this.topCategoryView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.topCategoryView = element
    //           }
    //         }

    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3859218777') {


    //         if (!element.showOnlyLoginUser) {
    //           this.topBrandView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.topBrandView = element
    //           }
    //         }

    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH4784391729') {


    //         if (!element.showOnlyLoginUser) {
    //           this.topProductView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.topProductView = element
    //           }
    //         }

    //       }
    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH6829117111') {


    //         if (!element.showOnlyLoginUser) {
    //           this.bestSellerProduct = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.bestSellerProduct = element
    //           }
    //         }

    //       }


    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH5387819003') {


    //         if (!element.showOnlyLoginUser) {
    //           this.websiteProduct = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.websiteProduct = element
    //           }
    //         }


    //       }

    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH7173940210') {



    //         if (!element.showOnlyLoginUser) {
    //           this.recommendationView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.recommendationView = element
    //           }
    //         }

    //       }

    //       //DONE
    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH3057948827') {

    //         if (!element.showOnlyLoginUser) {
    //           this.recentlyView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.recentlyView = element
    //           }
    //         }


    //       }

    //       if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionUid == 'CMSH2092496496') {

    //         if (!element.showOnlyLoginUser) {
    //           this.frequentlyView = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.frequentlyView = element
    //           }
    //         }

    //       }

    //       //DONE
    //       if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS2851504776') {

    //         if (!element.showOnlyLoginUser) {
    //           this.subSlider2 = element
    //         } else {
    //           if (this.user.userUniqueId) {
    //             this.subSlider2 = element
    //           }
    //         }


    //       }
    //     });


    //     if (this.user && this.cartList && this.cartList.length > 0) {
    //       this.previewDataList.forEach((element: any) => {
    //         if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
    //           element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
    //             if (element1.itemList && element1.itemList.length > 0) {
    //               element1.itemList.forEach((item: any) => {
    //                 this.cartList.forEach((cartItem: any) => {
    //                   if (item.itemUid == cartItem.itemUid) {
    //                     item.isAdded = true
    //                     item.quantity = cartItem.quantity;
    //                     item.cartId = cartItem.cartId;
    //                   }
    //                 });
    //               });
    //             }
    //           });
    //         }
    //       });
    //     }

    //     this.isLoading = false;
    //   } else {
    //     this.isLoading = false;
    //   }
    // }, err => {
    //   this.dataService.throwError(err)
    // })
  }



  getHomePageList() {
    // this.isLoading = true;
    this.pageListloadedSub = this.pageListLoaded$.subscribe(loadedBrands => {
      if (!loadedBrands) {
        this.store.dispatch(new getHomePageLists());
        // this.isLoading = false;
      }
    })
    this.pageList$.subscribe(result => {
      this.data = result;
      // this.isLoading = false;
      this.getPageList = result;
      this.getPageList.forEach((element: any) => {
        if (element.ecommPageName == 'Home Page') {
          this.homePage = element;
          this.getSeqList();
        }
        if (element.ecommPageName == 'List Page') {
          this.ecommPageUid = element.ecommPageUid;
        }
      });
    })

    // this.isLoading = true;
    // this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['ECOMM_PAGE_LIST']).subscribe(result => {
    //   this.data = result;
    //   if (this.dataService.appConstant.SUCCESS === result.status) {
    //     this.getPageList = this.data.result;
    //     this.getPageList.forEach((element: any) => {
    //       if (element.ecommPageName == 'Home Page') {
    //         this.homePage = element;
    //       }
    //     });
    //     setTimeout(() => {
    //       this.getSeqList();
    //     }, 1000)
    //   } else {
    //     this.isLoading = false;
    //   }
    // }, err => {
    //   this.dataService.throwError(err)
    // })
  }


  getPagePreviewList() {
    // this.isLoading = true;
    this.dataService.openGetRequest(this.dataService.appConstant.SERVER_URLS['GET_PAGE_PREVIEW_LIST'] + this.homePage.ecommPageUid + "/" + this.userUniqueId).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        // this.isLoading = false;

        //CHECK ITEM ALREADY ADDED INTO CART OR NOT
        this.data.result.forEach((element: any) => {
          if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
            element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
              if (element1.itemList && element1.itemList.length > 0) {
                element1.itemList.forEach((item: any) => {
                  item.isAdded = false
                  item.quantity = 1;
                });
              }
            });
          }
        });

        this.freshPreviewDataList = this.data.result;
        this.previewDataList = this.data.result;

        this.previewDataList.forEach((element, key) => {
          element.previewListIndex = key

          //DONE
          if (element.pageContentUid == 'PGC2725171252') {
            this.mainSlider = element
          }
          //DONE
          if (element.pageContentUid == 'PGC7874776730') {
            this.topCategoryView = element
          }
          //DONE
          if (element.pageContentUid == 'PGC2725171253') {
            this.topBrandView = element
          }
          //DONE
          if (element.pageContentUid == 'PGC2725171254') {
            this.topProductView = element
          }


          //DONE
          if (element.pageContentUid == 'PGC2725171255') {
            this.bestSellerProduct = element
          }


          //DONE
          if (element.pageContentUid == 'PGC2725171256') {
            this.websiteProduct = element
          }

          if (element.pageContentUid == 'PGC3966040336') {
            this.recommendationView = element
          }

          //DONE
          if (element.pageContentUid == 'PGC2725171257') {
            this.recentlyView = element;
          }

          if (element.pageContentUid == 'PGC1875104249') {
            this.frequentlyView = element
          }

          //DONE
          if (element.cmsSliderUid && element.cmsSliderUid == 'CMSS2851504776') {
            this.subSlider2 = element
          }
        });



        if (this.user && this.cartList && this.cartList.length > 0) {
          this.previewDataList.forEach((element: any) => {
            if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
              element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
                if (element1.itemList && element1.itemList.length > 0) {
                  element1.itemList.forEach((item: any) => {
                    this.cartList.forEach((cartItem: any) => {
                      if (item.itemUid == cartItem.itemUid) {
                        item.isAdded = true
                        item.quantity = cartItem.quantity;
                        item.cartId = cartItem.cartId;
                      }
                    });
                  });
                }
              });
            }
          });
        }
      } else {
        // this.isLoading = false;
      }
    }, err => {
      this.dataService.throwError(err)
    })
  }




  itemDetail(item: any) {
    // if (item.itemLink) {
    //   this.router.navigate(['/web/ag/product', item.itemLink]);
    // } else {
      this.router.navigate(['/web/ag/product'], { queryParams: { itemUid: item.itemUid } });
    // }
  }





  public addToCart(productListIndex: any, cmsSectionContentIndex: any, itemIndex: any): void {
    this.productListIndex = productListIndex;
    this.cmsSectionContentIndex = cmsSectionContentIndex;
    this.itemIndex = itemIndex;
    if (this.user && this.user.userUniqueId) {
      this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isLoading = true;
      this.proceedToAdd = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex];
      this.proceedToAdd.userUid = this.user.userUniqueId;
      this.proceedToAdd.quantity = 1;
      this.dataService.postRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['ADD_TO_CART']).subscribe(result => {
        this.data = result;
        if (this.dataService.appConstant.SUCCESS === this.data.status) {
          this.proceedToAdd = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = true;
          this.dataService.appConstant.successMsg("Item added into cart....")
          this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isLoading = false;
          this.dataService.getCartList();
        } else {
          this.dataService.appConstant.errorMsg(this.data.message);
        }
      }, err => {
        this.dataService.throwError(err)
      })
    } else {
      sessionStorage.setItem('pageName', "HomePage")
      sessionStorage.setItem('productListIndex', this.productListIndex)
      sessionStorage.setItem('cmsSectionContentIndex', this.cmsSectionContentIndex)
      sessionStorage.setItem('itemIndex', this.itemIndex)
      this.dataService.sendMessage("ProceedForLogin")
    }

  }


  public updateCartQuantity(productListIndex: any, cmsSectionContentIndex: any, itemIndex: any, type: any): void {
    this.productListIndex = productListIndex;
    this.cmsSectionContentIndex = cmsSectionContentIndex;
    this.itemIndex = itemIndex;

    this.proceedToAdd = this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex];
    let Qty = this.proceedToAdd.quantity;
    if (type == "increase") {
      Qty += 1;
      if (Qty <= this.addToCartQtyLimit) {
        this.proceedToAdd.quantity =Qty;
      }

    } else {
      this.proceedToAdd.quantity -= 1;
    }

    if (this.proceedToAdd.quantity < 1) {
      this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
      this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
      this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
      this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
      this.removeItemFromCart(this.proceedToAdd.cartId);
    } else {
      if (Qty <= this.addToCartQtyLimit) {
      this.dataService.putRequest(this.proceedToAdd, this.dataService.appConstant.SERVER_URLS['EDIT_CART']).subscribe(response => {
        this.data = response;
        if (this.dataService.appConstant.SUCCESS === response.status) {
          this.dataService.getCartList();
        }
      }, err => {
        this.dataService.throwError(err)
      })
    }else{
      this.dataService.appConstant.errorMsg("Maximum item limit of "+this.addToCartQtyLimit+" reached!");
    }
    }
  }


  public removeItemFromCart(carId: any): void {
    this.dataService.deleteRequest(this.dataService.appConstant.SERVER_URLS['EDIT_CART'] + carId).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].quantity = 1;
        this.previewDataList[this.productListIndex].cmsSectionBO.cmsSectionContentBOList[this.cmsSectionContentIndex].itemList[this.itemIndex].isAdded = false;
        this.dataService.getCartList();
      } else {
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    });
  }


  updateProductList() {
    this.previewDataList = this.freshPreviewDataList;
    //CHECK ITEM ALREADY ADDED INTO CART OR NOT
    this.cartList = JSON.parse(localStorage.getItem("cartList"));
    if (this.user && this.cartList && this.cartList.length > 0) {
      this.previewDataList.forEach((element: any) => {
        if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
          element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
            if (element1.itemList && element1.itemList.length > 0) {
              element1.itemList.forEach((item: any) => {
                this.cartList.forEach((cartItem: any) => {
                  if (item.itemUid == cartItem.itemUid) {
                    item.isAdded = true
                    item.quantity = cartItem.quantity;
                    item.cartId = cartItem.cartId;
                  }
                });
              });
            }
          });
        }
      });
    } else {
      this.previewDataList.forEach((element: any) => {
        if (element.cmsSectionBO && element.cmsSectionBO.cmsSectionContentBOList && element.cmsSectionBO.cmsSectionContentBOList.length > 0) {
          element.cmsSectionBO.cmsSectionContentBOList.forEach((element1: any) => {
            if (element1.itemList && element1.itemList.length > 0) {
              element1.itemList.forEach((item: any) => {
                item.isAdded = false
                item.quantity = 1;
              });
            }
          });
        }
      });
    }
  }


  ngOnDestroy() {
    if (this.pageListloadedSub) {
      this.pageListloadedSub.unsubscribe();
    }
    if (this.pageListByIdloadedSub) {
      this.pageListByIdloadedSub.unsubscribe();
    }
    if (this.pageWithSeqNoloadedSub) {
      this.pageWithSeqNoloadedSub.unsubscribe();
    }
  }

  gotoMobileSearch(searchKeyMobile) {
    if (searchKeyMobile.length > 1) {
      this.searchKeyMobile = searchKeyMobile;
      this.router.navigate(['/web/ag/search'], { queryParams: { tag: searchKeyMobile } });
    }
  }

  changeWare() {
    this.dataService.sendMessage('Change_Store');
  }


  showDownloadOnMobile() {
    // DOWNLOAD APP BUTTON
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
        } else {
        }
        this.deferredPrompt = null;
      });
  }

  // on banner click
  onBannerClick(imgs) {
    console.log(imgs);

    let brands: boolean = false;
    let brandUid: any = [];
    let category: boolean = false;
    let categoryUid: any = [];
    let item: boolean = false;
    let itemUid: any = [];
    let discountUid: any = [];
    let discount: boolean = false;
    if (imgs.sliderLinkType == 2 || imgs.sliderLinkType == 1) {
      let url = imgs.sliderLinkValue
      window.open(url, '_blank');
    } else if (imgs.sliderLinkType == 5) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.itemUid) {
            item = true;
            itemUid.push(element.itemUid);
          }
        });
        if (item) {
          console.log("1528");
          let navigationExtras: NavigationExtras = {
            state: {
              sliderItemUids: itemUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 6) {
      console.log(imgs.sliderLinkType);
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.itemUid) {
            item = true;
            itemUid.push(element.itemUid);
          }
        });
        console.log(item);
        if (item) {
          console.log("1549");
          let navigationExtras: NavigationExtras = {
            state: {
              sliderItemUids: itemUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.brandUid) {
            item = true;
            brandUid.push(element.brandUid);
          }
        });
        console.log(item);
        if (item) {
          console.log("1549");
          let navigationExtras: NavigationExtras = {
            state: {
              sliderBrandUids: brandUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 7) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.categoryUid) {
            category = true;
            categoryUid.push(element.categoryUid);
          }
        });

        if (category) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderCategorys: categoryUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 7) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.itemUid) {
            item = true;
            itemUid.push(element.itemUid);
          }
        });

        if (item) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderItemUids: itemUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    } else if (imgs.sliderLinkType == 8) {
      if (imgs.sliderItemBOList && imgs.sliderItemBOList.length > 0) {
        imgs.sliderItemBOList.forEach(element => {
          if (element.discountUid) {
            discount = true;
            discountUid.push(element.discountUid);
          }
        });

        if (discount) {
          let navigationExtras: NavigationExtras = {
            state: {
              sliderDiscountUids: discountUid
            }
          };
          this.router.navigate(['web/ag/products'], navigationExtras);
        }
      }
    }
  }

}
