

<section id="mobileHeader" class="mobileView">
  <div class="container-fluid">
      <div class="row mobileFixedHeader primaryGradient">
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mobileHeadPadT" data-toggle="modal"
              data-target="#mobileSideBar">
              <i class="fa fa-bars mMenuToggle mT5 " aria-hidden="true"></i>
          </div>
          <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 padL0 mobileHeadPadT" 
          data-toggle="modal" data-target="#mobileSideBar">
              <div class="headerWithBackArrow">
                Store Locator
              </div>
          </div>
      </div>
  </div>
</section>


<section id="storeloaction" *ngIf="!isLoading">
    <div class="container mVTopMrg">
      <div class="row firstrow" *ngIf="storesList.length>0">
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" *ngFor="let store of storesList">
          <div class="storemaindiv">
            <h2>{{store.wareHouseName}} </h2>

            
              <div style="padding: 7px 16px 0px;">
                <span *ngIf="store.street1">{{store.street1}},</span>
                <br> 
                <span *ngIf="store.street2">{{store.street2}}</span>
              </div>
              <div style="padding: 0px 16px 0px;">
                <span *ngIf="store.city">{{store.city}},</span> 
                <span *ngIf="store.state">{{store.state}},</span>
                <span *ngIf="store.country">{{store.country}}</span>
                <span *ngIf="store.zipCode">-{{store.zipCode}}</span>
              </div>

            <p *ngIf="store.phone" style="padding: 0px 16px;">Phone No : {{store.phone}}</p>
            <p *ngIf="store.phone" style="padding: 3px 16px 0px;">Email : {{store.email}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  

  
<skeleton-loader *ngIf="isLoading"></skeleton-loader>