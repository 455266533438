<div class="container accountContainer">
    <h1 id="heading" style="font-size: 17px;"><strong>Delete Your Account</strong></h1>
    <p>We value your privacy and understand that you may wish to delete your account. If you no longer wish to use our services and want to delete your account, please follow the steps below:<br><br></p>
    <h6 id="heading"><strong>How to Delete Your Account</strong> </h6>
    <ol >
        <li id="heading"><strong>In-App Account Deletion:</strong></li>
        <ul>
            <li >Open the app on your device.
                Navigate to the Profile section.
                Select the Delete Account option.
                Follow the prompts to complete the deletion process.</li>
                <li >Request Account Deletion via Email:</li>
        </ul>
         <li id="heading"><strong>Request Account Deletion via Email:</strong>
        </li>
        <ul>
            <li>If you are unable to access the app, you can request account deletion by contacting us at <a href="mailto:foodjunctionstore2@gmail.com">foodjunctionstore2@gmail.com.</a>
            </li>
            <li>Please include your account details (e.g., mobile number, email address) to help us process your request efficiently.</li>
        </ul>
    </ol>
    <h6 id="heading"><strong>What Happens After Account Deletion</strong></h6>
    <ul>
        <li>All your personal data associated with your account will be permanently deleted.</li>
        <li>This action is irreversible. Once your account is deleted, we will not be able to recover your data.</li>
        
    </ul>
    <p>If you have any questions or need further assistance, please contact our support team at <a href="mailto:foodjunctionstore2@gmail.com">foodjunctionstore2@gmail.com.</a></p>
        <p>Thank you for using our service.</p>
</div>